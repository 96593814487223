<template>
  <div v-if="country" class="flex">
    <div class="mr-2 rounded inline-block">
      <img
        :src="
          require('@/assets/icons/flags/' + country.iso.toLowerCase() + '.svg')
        "
        width="23"
        height="17"
        loading="lazy"
      />
    </div>
    <span>{{ country.name }}</span>
  </div>
  <span v-else class="text-th-primary-gray">
    {{ $t('common.orders.not_defined') }}</span
  >
</template>
<script>
import { countries, euCountries } from '@/utils/address'
import safeGet from 'just-safe-get'
export default {
  props: {
    scope: {
      type: Object,
      default: null
    },
    origin: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      countries,
      euCountries
    }
  },
  computed: {
    availableCountries() {
      return [...this.euCountries, ...this.countries]
    },
    originCountry() {
      return (
        this.origin ||
        safeGet(this.scope?.row, this.scope?.column?.property, null)
      )
    },
    country() {
      const country = this.availableCountries.find(
        (country) =>
          country.iso.toLowerCase() === this.originCountry?.toLowerCase()
      )
      return country
    }
  }
}
</script>
