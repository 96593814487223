<template>
  <th-page-wrapper>
    <!-- Table -->
    <th-datatable
      ref="table"
      table-identifier="order-transaction-table"
      sort-columns-enabled
      do-route
      do-route-filters
      force-meta-check
      show-filter
      headers-filterable
      prune-search-filters
      sortable
      sort-type="remote"
      resource="transactionsV3"
      :handle-export="handleExport"
      :wrap-query-in-object="true"
      route-base="/orders/orders_transactions"
      :headers="headers"
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :headers-min-active="minActiveSet"
      :show-operations="false"
      :search-filters="filtersList"
      @loading-error="handleLoadingError"
      @headers-config="handleHeadersConfig"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import orderSetup from '../constants/order'
import statusText from '../components/status-text'
import originText from '../components/origin-text'
import originCountry from '../components/origin-country'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'
import th from '@tillhub/javascript-sdk'
import { useExportsStore } from '@/store/exports'

export default {
  name: 'ReportsOrdersTransactionsList',
  beforeRouteEnter: (to, _, next) => {
    applyFiltersBeforeRouteEnter({
      path: to.path,
      query: to.query,
      next,
      sort: '-requestTimestamp',
      dateFilterName: 'requestTimestamp'
    })
  },
  beforeRouteUpdate(to, _, next) {
    applyFiltersBeforeRouteEnter({
      path: to.path,
      query: to.query,
      next,
      sort: '-requestTimestamp',
      dateFilterName: 'requestTimestamp'
    })
  },
  metaInfo() {
    return {
      title: this.$t('pages.orders_transactions.title')
    }
  },
  setup() {
    const transactionConst = orderSetup.setup()
    const brandsLabels = {}
    transactionConst.paymentMethodBrands.forEach((brand) => {
      brandsLabels[brand.value] = brand.label
    })
    const paymentMethodsLabels = {}
    transactionConst.paymentMethodCodes.forEach((method) => {
      paymentMethodsLabels[method.value] = method.label
    })
    const transactionTypesLabels = {}
    transactionConst.transactionTypes.forEach((type) => {
      transactionTypesLabels[type.value] = type.label
    })
    const processingStatusDescriptionsLabels = {}
    transactionConst.processingStatusDescriptions.forEach((type) => {
      processingStatusDescriptionsLabels[type.value] = type.label
    })
    return {
      paymentMethods: transactionConst.paymentMethods,
      brands: transactionConst.paymentMethodBrands,
      origins: transactionConst.origins,
      results: transactionConst.transactionResult,
      transactionTypes: transactionConst.transactionTypes,
      paymentMethodCodes: transactionConst.paymentMethodCodes,
      states: transactionConst.transactionStates,
      processingStatusDescriptions:
        transactionConst.processingStatusDescriptions,
      brandsLabels,
      paymentMethodsLabels,
      transactionTypesLabels,
      processingStatusDescriptionsLabels
    }
  },
  data() {
    return {
      headersDefaultHide: [
        'identificationReferenceId',
        'identificationShortId',
        'channelId',
        'identificationTransactionId',
        'identificationInvoiceId',
        'mode',
        'paymentRecurrenceMode',
        'paymentTerminalId',
        'paymentCutoverTimestamp',
        'paymentPresentationUsage',
        'identificationShopperId',
        'customer.email',
        'customer.companyName',
        'source',
        'processingStatusDescription',
        'processingReturnValueDescription',
        'processingCode',
        'accountNumber',
        'accountExpiry',
        'accountIban',
        'accountHolder',
        'paymentClearingFxRate',
        'paymentClearingDate',
        'paymentClearingDescriptor',
        'accountBankName',
        'accountBank',
        'accountCountry',
        'customer.ipCountry',
        'insuranceProvider',
        'insuranceId',
        'insuranceFinalizeDate',
        'instalmentNumberOfInstalments',
        'instalmentInterestRate'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies',
      localConfiguration: 'Config/getLocalConfiguration',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),
    headersConfig() {
      return safeGet(
        this.localConfiguration,
        'settings.headerFilters.orders_transactions',
        {}
      )
    },
    documentExport() {
      return this.$checkPermissions({ scopes: ['orders_transactions:export'] })
    },
    headers() {
      return [
        {
          field: 'requestTimestamp',
          label: this.$t(
            'pages.orders_transactions.all.headers.request_timestamp'
          ),
          minWidth: 190,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            return row.requestTimestamp
              ? this.$date.formatDateTime(row.requestTimestamp)
              : '-'
          }
        },
        {
          field: 'identificationUniqueId',
          label: this.$t('pages.orders_transactions.all.headers.unique_id'),
          minWidth: 265,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'metadata.origin',
          label: this.$t('pages.orders_transactions.all.headers.location'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          customRowComponent: originText
        },
        {
          field: 'paymentPresentationAmount',
          label: this.$t('pages.orders_transactions.all.headers.total_amount'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          align: 'right',
          formatter: (row) => {
            return row.paymentPresentationAmount &&
              row.paymentPresentationCurrency
              ? this.$formatCurrency(
                  row?.paymentPresentationAmount,
                  row?.paymentPresentationCurrency
                )
              : '-'
          }
        },
        {
          field: 'paymentPresentationCurrency',
          label: this.$t('pages.orders_transactions.all.headers.currency'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'paymentMethodCode',
          label: this.$t(
            'pages.orders_transactions.all.headers.payment_origin'
          ),
          fallback: '-',
          minWidth: 210,
          truncate: true,
          formatter: (row) => {
            return this.paymentMethodsLabels[row?.paymentMethodCode] || '-'
          }
        },
        {
          field: 'paymentMethodBrandCode',
          label: this.$t('pages.orders_transactions.all.headers.payment_brand'),
          fallback: '-',
          minWidth: 170,
          truncate: true,
          formatter: (row) => {
            return this.brandsLabels[row?.paymentMethodBrandCode] || '-'
          }
        },
        {
          field: 'customer.displayName',
          label: this.$t('pages.orders_transactions.all.headers.customer_name'),
          fallback: '-',
          minWidth: 160,
          truncate: true,
          formatter: (row) => row.customer && row.customer.displayName
        },
        {
          field: 'state',
          label: this.$t('pages.orders_transactions.all.headers.result'),
          fallback: '-',
          minWidth: 120,
          customRowComponent: statusText,
          truncate: true
        },
        {
          field: 'transactionType',
          label: this.$t(
            'pages.orders_transactions.all.headers.transaction_type'
          ),
          fallback: '-',
          minWidth: 190,
          truncate: true,
          formatter: (row) => {
            return (
              this.transactionTypesLabels[row?.transactionType] ||
              row?.transactionType ||
              '-'
            )
          }
        },
        {
          field: 'channelName',
          label: this.$t('pages.orders_transactions.all.headers.sales_channel'),
          fallback: '-',
          minWidth: 270,
          truncate: true
        },
        {
          field: 'identificationReferenceId',
          label: this.$t('pages.orders_transactions.all.headers.reference_id'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'identificationShortId',
          label: this.$t('pages.orders_transactions.all.headers.short_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'channelId',
          label: this.$t('pages.orders_transactions.all.headers.channel_id'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'identificationTransactionId',
          label: this.$t(
            'pages.orders_transactions.all.headers.transaction_id'
          ),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: 'identificationInvoiceId',
          label: this.$t('pages.orders_transactions.all.headers.invoice_id'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'mode',
          label: this.$t(
            'pages.orders_transactions.all.headers.transaction_mode'
          ),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: 'paymentRecurrenceMode',
          label: this.$t(
            'pages.orders_transactions.all.headers.recurrence_mode'
          ),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: 'paymentTerminalId',
          label: this.$t('pages.orders_transactions.all.headers.terminal_id'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'paymentCutoverTimestamp',
          label: this.$t(
            'pages.orders_transactions.all.headers.cutover_timestamp'
          ),
          minWidth: 170,
          truncate: true,
          formatter: (row) => {
            return row.paymentCutoverTimestamp
              ? this.$date.formatDateTime(row.paymentCutoverTimestamp)
              : '-'
          }
        },
        {
          field: 'paymentPresentationUsage',
          label: this.$t('pages.orders_transactions.all.headers.usage'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'identificationShopperId',
          label: this.$t('pages.orders_transactions.all.headers.shopper_id'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'customer.email',
          label: this.$t('pages.orders_transactions.all.headers.email'),
          fallback: '-',
          minWidth: 120,
          truncate: true,
          formatter: (row) => row.customer && row.customer.email
        },
        {
          field: 'customer.company',
          label: this.$t('pages.orders_transactions.all.headers.company_name'),
          fallback: '-',
          minWidth: 170,
          truncate: true,
          formatter: (row) => row.customer && row.customer.company
        },
        {
          field: 'source',
          label: this.$t('pages.orders_transactions.all.headers.source_txn'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        },
        {
          field: 'processingStatusDescription',
          label: this.$t(
            'pages.orders_transactions.all.headers.processing_status'
          ),
          fallback: '-',
          minWidth: 200,
          customRowComponent: statusText,
          truncate: true
        },
        {
          field: 'processingReturnValueDescription',
          label: this.$t(
            'pages.orders_transactions.all.headers.processing_message'
          ),
          fallback: '-',
          minWidth: 220,
          truncate: true
        },
        {
          field: 'processingCode',
          label: this.$t(
            'pages.orders_transactions.all.headers.processing_code'
          ),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: 'accountNumber',
          label: this.$t('pages.orders_transactions.all.headers.card_number'),
          fallback: '-',
          minWidth: 160,
          truncate: true
        },
        {
          field: 'accountExpiry',
          label: this.$t('pages.orders_transactions.all.headers.expiry'),
          fallback: '-',
          minWidth: 150,
          truncate: true
        },
        {
          field: 'accountIban',
          label: this.$t(
            'pages.orders_transactions.all.headers.account_number'
          ),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'accountHolder',
          label: this.$t(
            'pages.orders_transactions.all.headers.account_holder'
          ),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'paymentClearingFxRate',
          label: this.$t('pages.orders_transactions.all.headers.clearing_rate'),
          fallback: '-',
          minWidth: 180,
          truncate: true
        },
        {
          field: 'paymentClearingDate',
          label: this.$t('pages.orders_transactions.all.headers.clearing_date'),
          fallback: '-',
          minWidth: 200,
          formatter: (row) => {
            return row.paymentClearingDate
              ? this.$date.formatDateTime(row.paymentClearingDate)
              : '-'
          }
        },
        {
          field: 'paymentClearingDescriptor',
          label: this.$t('pages.orders_transactions.all.headers.descriptor'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'accountBankName',
          label: this.$t('pages.orders_transactions.all.headers.bank_name'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'accountBank',
          label: this.$t('pages.orders_transactions.all.headers.bic'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'accountCountry',
          label: this.$t('pages.orders_transactions.all.headers.bank_country'),
          fallback: '-',
          minWidth: 210,
          truncate: true,
          customRowComponent: originCountry
        },
        {
          field: 'customer.ipCountry',
          label: this.$t('pages.orders_transactions.all.headers.ip_country'),
          fallback: '-',
          minWidth: 120,
          truncate: true
        },
        {
          field: 'insuranceProvider',
          label: this.$t(
            'pages.orders_transactions.all.headers.insurance_provider'
          ),
          fallback: '-',
          minWidth: 230,
          truncate: true
        },
        {
          field: 'insuranceId',
          label: this.$t('pages.orders_transactions.all.headers.insurance_id'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'insuranceFinalizeDate',
          label: this.$t('pages.orders_transactions.all.headers.final_date'),
          fallback: '-',
          minWidth: 200,
          formatter: (row) => {
            return row.insuranceFinalizeDate
              ? this.$date.formatDateTime(row.insuranceFinalizeDate)
              : '-'
          },
          truncate: true
        },
        {
          field: 'instalmentNumberOfInstalments',
          label: this.$t(
            'pages.orders_transactions.all.headers.instalment_number'
          ),
          fallback: '-',
          minWidth: 200,
          truncate: true
        },
        {
          field: 'instalmentInterestRate',
          label: this.$t('pages.orders_transactions.all.headers.interest_rate'),
          fallback: '-',
          minWidth: 140,
          truncate: true
        }
      ]
    },
    filtersList() {
      return [
        {
          name: 'requestTimestamp',
          type: 'daterange',
          prop: ['start', 'end'],
          label: this.$t(
            'pages.orders_transactions.all.filters.request_timestamp'
          ),
          formatValue: (value) => this.$date.formatDateRange(value),
          noFutureDates: true,
          closable: false,
          default: getRangeFor[this.defaultDateSelected]?.()
        },
        {
          name: 'identificationUniqueId',
          label: this.$t(
            'pages.orders_transactions.all.filters.unique_id.label'
          ),
          type: 'input'
        },
        {
          name: 'paymentAuthorizationMethod',
          label: this.$t('pages.orders_transactions.all.filters.origin.label'),
          type: 'select',
          options: this.origins,
          placeholder: this.$t('common.inputs.placeholders.select')
        },
        {
          name: 'paymentPresentationAmount',
          label: this.$t('pages.orders_transactions.all.filters.total_amount'),
          type: 'currency-input',
          formatValue: this.formatCurrency
        },
        ...(this.currencies.length > 1
          ? [
              {
                name: 'paymentPresentationCurrency',
                type: 'currency-select',
                label: this.$t(
                  'pages.orders_transactions.all.filters.currency.label'
                ),
                value: this.currentCurrency,
                options: this.currencies
              }
            ]
          : []),
        {
          name: 'paymentMethodCode',
          label: this.$t(
            'pages.orders_transactions.all.filters.payment_method.label'
          ),
          type: 'select',
          options: this.paymentMethodCodes,
          placeholder: this.$t('common.inputs.placeholders.select')
        },
        {
          name: 'paymentMethodBrandCode',
          label: this.$t('pages.orders_transactions.all.filters.brand.label'),
          type: 'select',
          options: this.brands,
          placeholder: this.$t('common.inputs.placeholders.select')
        },
        {
          name: 'customer.displayName',
          label: this.$t(
            'pages.orders_transactions.all.filters.customer_name.label'
          ),
          type: 'input'
        },
        {
          name: 'state',
          label: this.$t('pages.orders_transactions.all.filters.result.label'),
          type: 'select',
          options: this.states,
          placeholder: this.$t('common.inputs.placeholders.select')
        },
        {
          name: 'transactionType',
          label: this.$t(
            'pages.orders_transactions.all.filters.transaction_type.label'
          ),
          type: 'select',
          options: this.transactionTypes,
          placeholder: this.$t('common.inputs.placeholders.select')
        },

        {
          name: 'channelName',
          label: this.$t(
            'pages.orders_transactions.all.filters.sales_channel.label'
          ),
          type: 'input'
        },
        {
          name: 'identificationReferenceId',
          label: this.$t(
            'pages.orders_transactions.all.filters.reference_id.label'
          ),
          type: 'input'
        },
        {
          name: 'identificationShortId',
          label: this.$t(
            'pages.orders_transactions.all.filters.short_id.label'
          ),
          type: 'input'
        },
        {
          name: 'channelId',
          label: this.$t(
            'pages.orders_transactions.all.filters.channel_id.label'
          ),
          type: 'input'
        },
        {
          name: 'identificationTransactionId',
          label: this.$t(
            'pages.orders_transactions.all.filters.transaction_id.label'
          ),
          type: 'input'
        },
        {
          name: 'identificationInvoiceId',
          label: this.$t(
            'pages.orders_transactions.all.filters.invoice_id.label'
          ),
          type: 'input'
        },
        {
          name: 'mode',
          label: this.$t(
            'pages.orders_transactions.all.filters.transaction_mode.label'
          ),
          type: 'input'
        },
        {
          name: 'paymentRecurrenceMode',
          label: this.$t(
            'pages.orders_transactions.all.filters.recurrence_mode.label'
          ),
          type: 'input'
        },
        {
          name: 'paymentTerminalId',
          label: this.$t(
            'pages.orders_transactions.all.filters.terminal_id.label'
          ),
          type: 'input'
        },
        {
          name: 'paymentCutoverTimestamp',
          label: this.$t(
            'pages.orders_transactions.all.filters.cutover_date.label'
          ),
          type: 'daterange',
          prop: ['start', 'end'],
          noFutureDates: true,
          preventDefaultDates: true,
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'paymentPresentationUsage',
          label: this.$t('pages.orders_transactions.all.filters.usage.label'),
          type: 'input'
        },
        {
          name: 'identificationShopperId',
          label: this.$t(
            'pages.orders_transactions.all.filters.shopper_id.label'
          ),
          type: 'input'
        },
        {
          name: 'customer.email',
          label: this.$t('pages.orders_transactions.all.filters.email.label'),
          type: 'input'
        },
        {
          name: 'customer.company',
          label: this.$t('pages.orders_transactions.all.filters.company.label'),
          type: 'input'
        },
        {
          name: 'source',
          label: this.$t(
            'pages.orders_transactions.all.filters.txn_source.label'
          ),
          type: 'input'
        },
        {
          name: 'processingStatusDescription',
          label: this.$t(
            'pages.orders_transactions.all.filters.process_status.label'
          ),
          type: 'select',
          options: this.processingStatusDescriptions,
          placeholder: this.$t('common.inputs.placeholders.select')
        },
        {
          name: 'processingReturnValueDescription',
          label: this.$t(
            'pages.orders_transactions.all.filters.process_message.label'
          ),
          type: 'input'
        },
        {
          name: 'processingCode',
          label: this.$t(
            'pages.orders_transactions.all.filters.process_code.label'
          ),
          type: 'input'
        },
        {
          name: 'accountNumber',
          label: this.$t(
            'pages.orders_transactions.all.filters.card_number.label'
          ),
          type: 'card-input',
          maxLength: 19
        },
        {
          name: 'accountExpiry',
          label: this.$t('pages.orders_transactions.all.filters.expiry.label'),
          type: 'expiry-date-input',
          placeholder: 'mm/yyyy'
        },
        {
          name: 'accountIban',
          label: this.$t(
            'pages.orders_transactions.all.filters.account_number.label'
          ),
          type: 'input'
        },
        {
          name: 'accountHolder',
          label: this.$t('pages.orders_transactions.all.filters.holder.label'),
          type: 'input'
        },
        {
          name: 'paymentClearingFxRate',
          label: this.$t(
            'pages.orders_transactions.all.filters.clearing_rate.label'
          ),
          type: 'number-input',
          upperLimit: 100.0
        },
        {
          name: 'paymentClearingDate',
          label: this.$t(
            'pages.orders_transactions.all.filters.clearing_date.label'
          ),
          type: 'daterange',
          prop: ['start', 'end'],
          noFutureDates: true,
          preventDefaultDates: true,
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'paymentClearingDescriptor',
          label: this.$t(
            'pages.orders_transactions.all.filters.descriptor.label'
          ),
          type: 'input'
        },
        {
          name: 'accountBankName',
          label: this.$t(
            'pages.orders_transactions.all.filters.bank_name.label'
          ),
          type: 'input'
        },
        {
          name: 'accountBank',
          label: this.$t('pages.orders_transactions.all.filters.bic.label'),
          type: 'input'
        },
        {
          name: 'accountCountry',
          label: this.$t(
            'pages.orders_transactions.all.filters.bank_country.label'
          ),
          type: 'input'
        },
        {
          name: 'customer.ipCountry',
          label: this.$t(
            'pages.orders_transactions.all.filters.ip_country.label'
          ),
          type: 'ip-input'
        },
        {
          name: 'insuranceProvider',
          label: this.$t(
            'pages.orders_transactions.all.filters.insurance_provider.label'
          ),
          type: 'input'
        },
        {
          name: 'insuranceId',
          label: this.$t(
            'pages.orders_transactions.all.filters.insurance_id.label'
          ),
          type: 'input'
        },
        {
          name: 'insuranceFinalizeDate',
          label: this.$t(
            'pages.orders_transactions.all.filters.final_date.label'
          ),
          type: 'daterange',
          prop: ['start', 'end'],
          noFutureDates: true,
          preventDefaultDates: true,
          formatValue: (value) => this.$date.formatDateRange(value)
        },
        {
          name: 'instalmentNumberOfInstalments',
          label: this.$t(
            'pages.orders_transactions.all.filters.instalment_number.label'
          ),
          type: 'input'
        },
        {
          name: 'instalmentInterestRate',
          label: this.$t(
            'pages.orders_transactions.all.filters.interest_rate.label'
          ),
          type: 'number-input',
          upperLimit: 100.0
        }
      ]
    },
    minActiveSet() {
      const defaultActive = [
        'requestTimestamp',
        'identificationUniqueId',
        'paymentAuthorizationMethod',
        'paymentPresentationAmount',
        'paymentPresentationCurrency',
        'paymentMethodCode',
        'paymentMethodBrandCode',
        'customer.displayName',
        'state',
        'transactionType',
        'channelName'
      ]

      return this.headers.filter((obj) => defaultActive.includes(obj.field))
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', async () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.orders_transactions',
        value: config || {}
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.orders_transactions.title')
        })
      })
    },
    formatCurrency(value) {
      if (Number.isFinite(+value))
        return this.$formatCurrency(value, this.defaultCurrency)
      return null
    },
    async handleExport(query) {
      try {
        const { data } = await th.transactionsV3().export({ query })

        const exportId = data?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.orders_transactions.title',
            date: new Date(),
            action: {
              entity: 'transactionsV3',
              handler: 'export',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.balances.title')
          })
        })
      }
    }
  }
}
</script>
